import React from "react";
import details from "./details";
import styles from "./Team.module.scss"
import Slider from 'react-slick';

interface PersonType {
  title: string;
  jobTitle: string;
  image: string;
}
interface TeamProps {
  mainTitle?: string;
}
export const Team: React.FC<TeamProps> = ({ mainTitle = 'Our people show up everyday to do extraordinary things.' }) => {
  const settings = {

    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          cssEase: "linear",
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  }
  const renderTeamMember = (person: PersonType) => (
    <>
      <div style={{ backgroundColor: "#0C2A31" }}><img src={person.image} alt={person.title} /></div>
      <div className={styles.teamContent}>
        <p>{person.title}</p>
        <p dangerouslySetInnerHTML={{ __html: person.jobTitle }} />
      </div>


    </>)
  return (
    <section className="section" style={{ backgroundColor: "#0C2A31" }}>

      <div className={`container  overflow-hidden ${styles.team}`}>
        <div className="row">
          <div className="col-10 col-xl-7 offset-md-1 pb-5">
            <h2 className="sTitle">{mainTitle}</h2>
          </div>
        </div>
        <div className={styles.boxWrapper} >
          <Slider className="d-block d-sm-none" {...settings}>
            {details.map((person: PersonType, i: number) => (
              <div key={i} className={styles.teamBox}>
                {renderTeamMember(person)}
              </div>
            ))}
          </Slider>
          {details.map((person: PersonType, idx: number) => (
            <div key={idx} className={`d-none d-sm-block ${styles.teamBox}`}>
              {renderTeamMember(person)}
            </div>
          ))
          }
        </div>
      </div>
    </section>


  );
}

export default Team;
