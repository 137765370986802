
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/this-machine-2",
      function () {
        return require("private-next-pages/this-machine-2/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/this-machine-2"])
      });
    }
  