export const details = [
  {
    title: 'Kaitlyn Castellow',
    jobTitle: 'Associate <br class="br"> Creative Director',
    image: '/persons/KaitlynCastellow.png',
  },
  {
    title: 'Alex Gronke',
    jobTitle: 'Editorial <br class="br"> Director',
    image: '/persons/AlexGronke.png',
  },
  {
    title: 'Nick Astor',
    jobTitle: 'Content <br class="br"> Director',
    image: '/persons/NickAstor.png',
  },
  {
    title: 'Christina Corrado',
    jobTitle: 'Senior Director <br class="br"> of Production',
    image: '/persons/ChristinaCorrado.png',
  },
  {
    title: 'Jenna Allen',
    jobTitle: 'Senior <br class="br"> Copywriter',
    image: '/persons/JennaAllen.png',
  },
  {
    title: 'Greg Rackages',
    jobTitle: 'Associate <br class="br"> Creative Director',
    image: '/persons/GregRackages.png',
  },
  {
    title: 'Michael Tucker',
    jobTitle: 'Executive <br class="br"> Creative Director',
    image: '/persons/MichaelTucker.png',
  },
  {
    title: 'Holli Burlison',
    jobTitle: 'Director of <br class="br"> Creative Services',
    image: '/persons/HolliBurlison.png',
  },
  {
    title: 'Tom Cocks',
    jobTitle: 'Creative <br class="br"> Director',
    image: '/persons/TomCocks.png',
  },
  {
    title: 'Fiona Ryan',
    jobTitle: 'Integrated <br class="br"> Account Director',
    image: '/persons/FionaRyan.png',
  },
  {
    title: 'Dave Feinberg',
    jobTitle: 'General <br class="br"> Manager',
    image: '/persons/DaveFeinberg.png',
  },
  {
    title: 'Natasha Martin',
    jobTitle: 'Associate <br class="br"> Creative Director',
    image: '/persons/NatashaMartin.png',
  },
  {
    title: 'Maggie Hunt',
    jobTitle: 'Account <br class="br"> Supervisor',
    image: '/persons/MaggieHunt.png',
  },
  {
    title: 'Deana Saïd Heywood',
    jobTitle: 'Creative <br class="br"> Operations Manager',
    image: '/persons/DeanaHeywood.png',
  },
  {
    title: 'Matt Sullivan',
    jobTitle: 'Senior <br class="br"> Copywriter',
    image: '/persons/MattSullivan.png',
  },
  {
    title: 'Joanna Wojcicka',
    jobTitle: 'Senior <br class="br"> Art Director',
    image: '/persons/JoannaWojcicka.png',
  },
  {
    title: 'Stu Reid',
    jobTitle: 'Content Producer <br class="br"> and Editor',
    image: '/persons/StuReid.png',
  },
  {
    title: 'Jess Cauchi',
    jobTitle: 'Associate <br class="br"> Creative Director',
    image: '/persons/JessCauchi.png',
  },
  {
    title: 'Katherine Barker',
    jobTitle: 'Senior Account <br class="br"> Director',
    image: '/persons/KatherineBarker.png',
  },
  {
    title: 'Rebecca Hill',
    jobTitle: 'Head of <br class="br"> Strategy',
    image: '/persons/RebeccaHill.png',
  },
  {
    title: 'Jason Winkler',
    jobTitle: 'Managing <br class="br"> Director',
    image: '/persons/JasonWinkler.png',
  },
  {
    title: 'Chloe Green',
    jobTitle: 'Senior Content <br class="br"> Writer',
    image: '/persons/ChloeGreen.png',
  },

  {
    title: 'Ruby Mason',
    jobTitle: 'Associate <br class="br"> Creative Director',
    image: '/persons/RubyMason.png',
  },
  {
    title: 'Zach Cross',
    jobTitle: 'Senior Account <br class="br"> Executive',
    image: '/persons/zachCross.png',
  },
  {
    title: 'Amanda Daloise',
    jobTitle: 'Account <br class="br"> Director',
    image: '/persons/daloise.png',
  },
  {
    title: 'Alex Palomo',
    jobTitle: 'Creative <br class="br"> Director',
    image: '/persons/palomo.png',
  },
  {
    title: 'Bri Hand',
    jobTitle: 'Creative <br class="br"> Director',
    image: '/persons/hand.png',
  },
  {
    title: 'Alex Whitcomb',
    jobTitle: 'Client <br class="br"> Director',
    image: '/persons/whitcomb.png',
  },
  {
    title: 'Allegra Stangvik',
    jobTitle: 'Account <br class="br"> Supervisor',
    image: '/persons/stangvik.png',
  },
];

export default details;
