import React, { ReactNode, useRef, useState } from "react";
import Slider from 'react-slick';
import places from './places'
import styles from './Offices.module.scss'

interface PlaceType {
  city: string;
  address: string;
  image: string;
}

interface OfficesProps {
  mainTitle?: string;
}
export const Offices: React.FC<OfficesProps> = ({ mainTitle = 'Showing up starts here.' }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null)
  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>, onClick: () => void) => {
    if (event.key === 'Enter') {
      if (onClick) {
        onClick();
      }
    }
  }
  const CustomNav = ({ onClick, active, city, address }: { onClick: () => void; active: boolean; city?: ReactNode; address?: string }) => (
    <div className={styles.navWrapper} onClick={onClick} onKeyUp={(e) => handleKeyUp(e, onClick)}>
      <div
        role="button"
        tabIndex={0}
        aria-label="nav"
        className={`${styles.trigger} ${active ? styles.activeNav : ''}`}
      ><h6>{city || ''}</h6></div>

      {address ? (
        <div dangerouslySetInnerHTML={{ __html: address }} />
      ) : null}
    </div>
  );
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          cssEase: "linear",
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ],
    beforeChange: (_current: number, next: number) => {
      setCurrentSlide(next);
    },
  }
  return (
    <section className={`section ${styles.offices}`}>
      <div className="container pe-0">
        <div className="row">
          <div className="col-10 col-xl-7 offset-md-1 pb-5">
            <h2 className="sTitle">{mainTitle}</h2>
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <div className={styles.sliderWrapper}>
            <Slider ref={sliderRef} {...settings}>
              {places.map((pl: PlaceType, idx: number) => (
                <div key={idx} >
                  <div className={`pe-0 pe-sm-5`}>
                    <div className={styles.image} style={{ backgroundImage: `url(${pl.image})` }}>
                      <img src={pl?.image} alt={pl.city} />
                    </div>
                    <div className={styles.mobileContent}>
                      <h6 className="pb-3">{pl.city}</h6>
                      <div dangerouslySetInnerHTML={{ __html: pl.address }} />
                    </div>
                  </div>
                </div>
              ))}


            </Slider>

          </div>
        </div>
        <div className={styles.customNav}>
          {places?.map((place: PlaceType, i: number) => (
            <CustomNav
              key={`nav-${i}`}
              onClick={() => {
                if (sliderRef.current) {
                  sliderRef.current.slickGoTo(currentSlide > i ? places.length + i : i + 1 === places.length ? i : currentSlide + i);
                }
              }}
              active={i === currentSlide}
              city={place?.city}
              address={place?.address}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
export default Offices;