const places = [
  {
    image: '/offices/ldn.png',
    city: 'London',
    address:
      '<p>60 Great Portland Street <br class="br"/> London <br class="br"/> W1W 7RT</p>',
  },
  {
    image: '/offices/sf.png',
    city: 'San Francisco',
    address:
      '<p>300 California Street  <br class="br" /> Suite 200 <br class="br" /> San Francisco <br class="br"/>CA 94104</p>',
  },
  {
    image: '/offices/ny2.png',
    city: 'New York',
    address:
      '<p>666 Third Avenue <br class="br"/> 7th Floor <br class="br"/> New York, NY 10017</p>',
  },
];
export default places;
